<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12" lg="4">
          <v-card class="shadow rounded-lg" :loading="loading">
            <v-card-text>
              <div class="text-center">
                <v-avatar size="70">
                  <v-img v-if="user.photo" :src="FILE_URL + user.photo"></v-img>
                  <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                </v-avatar>

                <div @click="onButtonClick" class="text-center pa-6 cursor-pointer">
                  <v-btn :loading="loading" dark color="blue" rounded
                         depressed>
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Modifier la photo
                  </v-btn>
                  <input accept="image/png,image/jpeg"
                         ref="uploader"
                         class="d-none"
                         type="file"
                         @change="uploadPhoto($event)">
                </div>
                <h4 class="mb-2">
                  <v-icon>mdi-account</v-icon>
                  {{ user.first_name }} {{ user.last_name }}
                </h4>
                <p>
                  <v-icon>mdi-email</v-icon>
                  {{ user.email }}
                </p>
                <h4 class="mb-2">  <v-icon>mdi-map-marker-multiple</v-icon> {{ user.cities.length }} Wilayas affectées</h4>

                <v-chip text-color="success"
                        style="background: #79d70f26"
                        class="mb-1 mr-1"
                        small v-for="(city,i) in user.cities" :key="i">
                  {{ city }}
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8">
          <v-card class="shadow rounded-lg">
            <v-toolbar flat>
              <v-tabs v-model="tabs" centered color="blue">
                <v-tab>Mofifier mon compte</v-tab>
                <v-tab>Mofifier le mot de passe</v-tab>
              </v-tabs>
            </v-toolbar>

            <v-tabs-items v-model="tabs">

              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-6">
                    <AccountForm/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-6">
                    <PasswordForm/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import PasswordForm from "./PasswordForm";
import AccountForm from "./AccountForm";
import {HTTP} from "@/http-common";

export default {
  components: {AccountForm, PasswordForm},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      loading: false,
      errors: '',
      tabs: null,
    }
  },
  methods: {
    uploadPhoto(file) {
      this.loading = true
      this.$Progress.start()
      this.errors = ''
      let formData = new FormData()
      formData.append('photo', file.target.files[0])
      HTTP.post('/update-profile-picture', formData).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.$store.commit('updateUserPicture', res.data.photo)
        this.$successMessage = 'Votre photo a été modifié avec succès !'
      }).catch(err => {
        this.loading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.uploader.click()
    },
  },
  created() {
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>

</style>