<template>
  <div>
    <form @submit.prevent="save">
      <v-text-field
          @input="errors && errors.current_password ? errors.current_password = '' : false"
          :error-messages="errors.current_password"
          v-model="form.current_password" label="Mot de passe actuel *"/>
      <v-text-field :type="show ? 'text' : 'password'"
                    @input="errors && errors.new_password ? errors.new_password = '' : false"
                    v-model="form.new_password"
                    :error-messages="errors.new_password"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Nouveau mot de passe *"/>
      <v-text-field
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @input="errors && errors.confirm_password ? errors.confirm_password = '' : false"
          :error-messages="errors.confirm_password"
          v-model="form.confirm_password" label="Confirmer le nouveau mot de passe *"/>
      <div class="text-right mt-3">
        <v-btn :loading="btnLoading" type="submit" class="rounded-lg" depressed color="primary">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      errors: '',
      form: {},
      show: false,
      btnLoading: false
    }
  },
  methods: {
    save() {
      this.btnLoading = true
      this.$Progress.start()
      this.errors = {}
      HTTP.post('/update-password', this.form).then(() => {
        this.btnLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Votre mot de pass a été modifié avec succès !'
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.btnLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>