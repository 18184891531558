<template>
  <div>
    <form @submit.prevent="save">
      <v-text-field @input="errors && errors.first_name ? errors.first_name = '' : false"
                    :error-messages="errors.first_name"
                    v-model="form.first_name" label="Nom *"/>
      <v-text-field @input="errors && errors.last_name ? errors.last_name = '' : false"
                    :error-messages="errors.last_name"
                    v-model="form.last_name" label="Prénom *"/>
      <v-text-field @input="errors && errors.email ? errors.email = '' : false"
                    :error-messages="errors.email"
                    v-model="form.email" label="Email *"/>
      <div class="text-right mt-3">
        <v-btn :loading="btnLoading" type="submit" class="rounded-lg" depressed color="primary">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      errors: {},
      form: {
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
        email: this.$store.state.user.email,
      },
      btnLoading: false
    }
  },
  methods: {
    save() {
      this.btnLoading = true
      this.$Progress.start()
      this.errors = {}
      HTTP.post('/update-account', this.form).then(() => {
        this.btnLoading = false
        this.$Progress.finish()
        let data = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
        }
        this.$store.commit('updateUser', data)
        this.$successMessage = 'Votre compte a été modifié avec succès !'
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.btnLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>